import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import { motion } from "framer-motion"
import Layout from "../components/layout"
import Seo from "../components/seo"
import LogoDiamond from "../components/LogoDiamond"
import FeatherIcon from 'feather-icons-react';

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />

    <Helmet>
    <body className="body-dark"></body>
    </Helmet>

<section className="grid grid-cols-1 lg:grid-cols-2 gap-0 h-screen">

    <div className="h-screen/34 lg:h-screen bg-owlgray px-8 py-2 sm:p-8 flex justify-center items-end sm:items-center">
            <form
              name="contact"
              method="post"
              action="/thank-you/"  
              data-netlify="true"
              data-netlify-honeypot="bot-field"  
              className="w-full md:w-3/4 lg:w-2/3 text-owlyellow px-3 text-sm"         
            >  
                <input type="hidden" name="form-name" value="contact" />   
                <label>
                  Name
                  <input type="text" name="name" id="name" className="w-full p-1 sm:p-2 mb-4 rounded-md"/>
                </label>
                <label>
                  Email
                  <input type="email" name="email" id="email" className="w-full p-1 sm:p-2 mb-4 rounded-md"/>
                </label>
                <label>
                  Subject
                  <input type="text" name="subject" id="subject" className="w-full p-1 sm:p-2 mb-4 rounded-md"/>
                </label>
                <label>
                  Message
                  <textarea name="message" id="message" rows="3" className="w-full p-1 sm:p-2 mb-4 rounded-md" />
                </label>
                <button type="submit" className="btn-yellow w-48 py-4 mt-5">
                  Submit
                  <FeatherIcon icon="external-link" size="24" className="stroke-owlwhite -mt-0.5 ml-3 float-right"/>                    
                  </button>

            </form>          
    </div>

    <div className="flex lg:h-screen items-center justify-center order-last lg:order-first bg-owlgreen p-2 sm:p-4 lg:p-8 text-center lg:text-left">
      <motion.div className="md:w-2/3"
        initial={{x: -300, opacity: 0}}
        animate={{x: 0, opacity: 1}}
        transition={{duration: 1}}
      >
        <div className="w-4 my-2 sm:my-8 mx-auto lg:mx-4 animate-bounce"><LogoDiamond className="fill-owlred hover:fill-owlred-dark"/></div>  
        <h1 className="display-2 w-full text-6xl xl:text-7xl text-owlwhite">Get in Touch</h1>
        <motion.hr 
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        />   
        <p className="text-owlwhite hover:text-owlyellow text-left mb-1">
        <FeatherIcon icon="mail" size="20" stroke="#fff" className="mr-2 mt-0.5 float-left"/>    
          <a href="mailto:hello@2owls.co.uk">hello@2owls.co.uk</a>
        </p>  
        <p className="text-owlwhite hover:text-owlyellow text-left">        
        <FeatherIcon icon="phone" size="20" stroke="#fff" className="mr-2 mt-0.5 float-left"/>            
          <a href="tel:+44 7906 568314">+44 7906 568314</a>
          </p>
      </motion.div>
    </div>


  </section>        

  </Layout>
)

export default ContactPage
